
.schedule{
    height: 100vh;
    width: 100%;
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.98),rgba(255, 255, 255, 0)),url('../assets/img/fondoTelaRosa.jpg'); */
    position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;

background: url(../assets/icons/img_ondas02.svg) center 30px no-repeat #ffffff;
    background-color: var(--main);

    position: relative;
  padding-bottom: 40px;
}






/* .divisor{
    content: "";
    position: absolute;
    width: 100%;
   height: 100px;
    background-image: url('../assets/img/separador.png');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: 0;
    left: 0;
} */

.scheduleTitle{
    margin-top: 150px;
    background-color: var(--lines);
    padding: 10px 20px;
    position: relative;
    font-family: "DM Serif Display", serif;
    color: white;
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;

}

.scheduleTitle::before{
    background-image: url(../assets/icons/img_cinta01.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: right;
    height: 80%;
    width: 50px;
    left: -50px;
    top: 5px;
}

.scheduleTitle::after{
    background-image: url(../assets/icons/img_cinta02.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: left;
    height: 80%;
    width: 50px;
    right: -50px;
    top: 5px;
}

.subtitle{
    margin: 10px 0;
    color: var(--lines);
    font-size: 24px;
    font-family: "DM Serif Display", serif;
}

.ahref{
    text-decoration: none;
}

@media only screen and (max-width:480px) {
.subtitle{
    font-size: 22px
}

.text{
    font-size: 18px;
}

.scheduleTitle{
    margin-top: 100px;
    background-color: var(--lines);
    padding: 10px 20px;
    position: relative;
    font-family: "DM Serif Display", serif;
    color: white;
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;

}
}