.giftsContainer{
    height: 100vh;
    width: 100%;
    background-image: url('');
    position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.giftsContainer::after{
    background-image: url('');
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100%;
    width: 100%;
    bottom: -1px;
}

.giftsContainer::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: -100vh;
    left: 0;
}