
.container{
    height: 5vh;
    width: 100%;
    /* background-image: url('../assets/img/fondoRepetible.png');
    background-position: bottom center;
    background-size: cover; */
    position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

}

.title{
    color: var(--lines);
    font-size: 12px;
}

.text{
    color: var(--lines);
    font-size: 10px;
}

/* .footerContainer::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../assets/icons/img_ondas05.svg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: -100vh;
    left: 0;
} */

@media only screen and (max-width:480px) {

}