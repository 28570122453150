/* CountDown Section */



.countDown{
    background-color: var(--main);
    background-repeat: no-repeat;
    background-size:cover; 

    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1%;

}



/* .countDown::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/flora-312815.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    z-index: 0;
    top: 0;
    left: 0;
} */



.container{
    width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;

}


.textContainer{
    height: 40%;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 3% 10%;
  
    color: var(--lines);


  }

.invitationText{
    padding: 3% 10%;
    text-align: center;
    text-justify: center;
    font-size: 30px;
    margin-top: 60px;

}

.comillas{
    width: 100px;
    height: 100px;
    color: var(--lines);
}

.timeLeftTitleContainer{
    min-height: 100px;
    
}
 
.timeLeftTitle{
    margin-top: 20px;
    color: var(--lines);
    opacity: 0;
    animation-name: timeLeftTitle;
    animation-duration: 2s;
    position: relative;
    animation-iteration-count: infinite;
    font-size: 14px;
}

@keyframes timeLeftTitle {
    from {opacity:0; font-size: 24px;}
    to {opacity: 100%; font-size:34px;}
  }

.clockFrameContainer{
    width: 100vw;
    
 position: relative;
    display: inline-block;
    text-align: center;
}

.frame{
    width: 380px;
    height: 380px;
    border-radius: 50%;
}

.clockContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
   position: absolute;
z-index: 2;
   left: 80px;
   right: 0;
   margin: 0 auto;
   width: 100px;
   top: 120px;
   transform: translate(-50%, -50%);
   
}

.clockColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid var(--color2);
    margin: 5px;
    padding: 5px;
  
}

.clockColumnLast{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
   
    margin: 5px;
    padding: 5px;
  
}
/* 
.borderFlower{
    width: 200px;
    position: absolute;
    left: -70px;
    top: 0;
} */

.clockFont{
    font-size:18px;
    color: var(--text);
    margin: 0 1px;
  }

@media only screen and (max-width:480px) {
    .countDown{

        padding-bottom: 10px;
    
    }
 

    .invitationText{
        padding: 3% 10%;
        text-align: center;
        text-justify: center;
        font-size: 20px;
    }
    
    .frame{
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .timeLeftTitleContainer{
        min-height: 50px;
        
    }

    .timeLeftTitle{
      
        margin-top: 5px;
       font-size:12px
    }


    @keyframes timeLeftTitle {
        from {opacity:0; font-size: 12px;}
        to {opacity: 100%; font-size:18px;}
      }

      .clockFont{
        font-size:16px;
      }
   
}




.presentationTitle{
    color: var(--lines);
    color: black;
    font-style: oblique;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.h1_super{
    font-family: "Allura", cursive !important;
    font-size: 200px;
    font-weight: bold;
    margin-top: 40%;
 
}
.h1{
    font-family: "Allura", cursive !important;
    color: var(--main);
    font-size: 90px;
    text-align: center;
 
}

.h2{
    font-family: "Allura", cursive !important;
    color: var(--main);
    font-size:60px;
    text-align: center;
}

.h3{
    font-family: "Big Caslon";
    font-weight: lighter;
    font-style: normal;
    color: var(--main);
    font-size: 15px;
    text-align: center;
}




@media only screen and (max-width:950px) {
    .h1{
        font-size: 70px !important; 
    }
    .h2{
        font-size: 50px !important; 
    }
}
