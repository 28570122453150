/* Presentation Section */
.presentation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}

.presentation::after{
    background-image: url('../assets/icons/img_ondas01.svg');
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100%;
    width: 101%;
    bottom: -110px;
}

.presentationTitle{
    position: absolute; 
    text-align: right;
    top: 10%;
    left: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.h1_super{
    text-align: right;
    color: var(--main-title);
    box-shadow: var( --color-1);
    font-family: "Great Vibes", cursive;
    font-size: 120px;
    font-weight: bold;
    line-height: 80%;
    margin-right:5%
 
}
.h1{
 
    color: var(--main-title);
    font-family: "DM Serif Display", cursive;
    font-size: 40px;
    line-height: 90px;
    margin-right:5%
 
}

.h2{

    color: var(--second-title);
    font-family: "DM Serif Display", cursive;
    text-align: right;
    margin-left:5%
}

.h3{
    font-family: "Big Caslon";
    color: var(--second-title);
    text-align: right;
    margin-right:5%
}

.h4{
    color: var(--second-title);
}

.arrowIcon{
    color: var(--icons);
    opacity: 100%;
    width: 40px;
    height: 40px;
    animation-name: arrow;
    animation-duration: 2s;
    position: relative;
    animation-iteration-count: infinite;
}

@keyframes arrow {
    from {opacity:100%; top:0; width: 70%;}
    to {opacity: 0%; top: 20px; width: 50%;}
  }

  @media only screen and (max-width:480px) {
    .h1{
 
   
        font-size: 80px;
      
     
    }

    .presentationTitle{
        position: absolute; 
        top: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;    
    }
    

  }