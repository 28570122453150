.container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 100px 0;
height: 35vh;
background-color: var(--secondary);

}

/* .container::before{
    background-image: url('../assets/icons/img_ondas01.svg');
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100%;
    width: 100%;

} */

.textContainer{
    text-align: center;
}

.h1Final{
    color: var(--text);
    font-family: "Great Vibes", cursive;
    font-size: 60px;
}

.h2Final{
    font-family: "Big Caslon";
    font-weight: lighter;
    color: var(--text);
}

.h3Final{
    font-family: "Big Caslon";
    font-weight: lighter;
    color: var(--text);
}

.link{
    font-family: "Big Caslon";
    font-weight: lighter;
    color: var(--text);
cursor: pointer;
}

.link:hover{
    color: var(--lines);
    text-decoration: underline;
}

.ahref{
    text-decoration: none;
}

.gift{
    margin-left: 80px;
}

.linksContainer{
    font-family: "Big Caslon";
    font-weight: lighter;
    text-align: right;
}


@media only screen and (max-width:480px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 100px 0;
    background-color: var(--main);
    
    }

    .gift{
        margin-left: 50px;
    }

    .linksContainer{
        text-align: center;
        margin-top: 20px;
      
    }

    .link{
        font-size: 15px;
    }
}
