*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
hr {
  margin-top:10%;
  height: 8px;
  background-color: black;
}
:root{
--color1: #ac726d;
--color2: #9a625d;
--color3: #88524e;
--color4: #75413e;
--color5: #63312e;
--main: #000;
--secondary:#88524e;
--secondary-dark: #b48d74;
--text: #fff;
--lines: #56341d;
--nude: #b7850f;
--color-6: #f2c7ad;


  --main-title: #fff;
  --second-title: #fff;

  --special-title: #ffd791;
  --icons: #fff;
  --button: #bfe3c0;
  --button-text: #fff;
  --button-hover: #d9e8ae;
  --button-hover-text: #fff;

  --color-1: #3F3F3D;
    --color-2: #7D735D;
    --color-3: #BAA67C;
    --color-4: #E6C6A3;
    --color-5: #AF8566;
    --color-9: #E5E9D0;
    --color-6: #A86B1D;
    --color-7: #F7E586;
    --color-8: #E6B64B;
    --fuente-1: 'Parisienne', cursive;
    --fuente-2: 'Playfair Display', serif;
    --grosor-fuente-regular: 400;
    --grosor-fuente-bold: 700;
    
}

body {
  font-family: "Quicksand", sans-serif;
    font-size: 1rem;
    color: #212529;

}

html{
  -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

p{
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  color: var(--lines);
}

*, ::after, ::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--color-5);
}

::-webkit-scrollbar-track {
  background: var(--color-4);
  border-radius: 10px;
}

@media only screen and (max-width:320px) {
  p{
      font-size: 12px
  }
  }

