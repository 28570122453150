.partyDetailsContainer{
  
    background-blend-mode: darken;
    background: linear-gradient(to left, transparent, white 0),url('../assets/img/FOTO-12-3.jpeg');
     /* Set linear gradient on the left */

  /* Combine background image and linear gradient */
  

    /* Set a specific height */
    background-attachment: scroll;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
padding-top: 20px;
padding-bottom: 100px;
}
.bg-text {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }

  
.dressSuitContainerDisabled{
    display: none;
}

.partyDetailsSubcontainer{
    align-items: center;
    justify-content: space-between;
    width: 190vw;
    
}

.container{
   display: flex;
   justify-content: center;
   text-align: center;
   margin: auto;



}
.textContainer2{
    font-family: "Big Caslon";
    color: var(--lines);
    text-shadow: 2px 2px 2px #fff;
    justify-content: left;
    align-items: left;
    text-align: left;
    width: 90%;
    max-width: 100%;
    margin:auto;
    margin-top:5%;
    margin-left: auto;
    margin-right: auto;
        /* width and color */

}

.textContainer{
    display: inline;
    color: var(--lines);
    text-shadow: 2px 2px 2px #fff;
    flex-direction: column;
    justify-content: right;
    align-items: right;
    padding-top: 20%;
        /* width and color */

}
.comillas{
    position: absolute;
    width: 100px;
    height: 100px;
    color: var(--lines);
}
/* .partyDetailsContainer::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/flora-312815.svg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: -100vh;
    left: 0;
} */

.classContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-dark);
    border-radius:10%;
    padding:30px;

    height: 480px;
    width: 340px;
    margin: 5px;
}

.dressSuitContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.title{
    font-family: "DM Serif Display", serif;
    background-color: var(--lines);
    padding: 10px 20px;
    position: relative;
 margin-bottom: 50px;
    color: white;
    text-align: center;
    font-size: 28px;
 
}


.title::before{
    background-image: url(../assets/icons/img_cinta01.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: right;
    height: 80%;
    width: 50px;
    left: -50px;
    top: 5px;
}

.title::after{
    background-image: url(../assets/icons/img_cinta02.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: left;
    height: 80%;
    width: 50px;
    right: -50px;
    top: 5px;
}

.cardTitle{
    font-size: 26px;
    font-weight: 600;
    color: var(--text);
}

.text{
    color: var(--text);
    text-align: left;
    margin-right: 20%;
}

.ahref{
    text-decoration: none;
}


.h1_super{
    font-family: "Great Vibes", cursive;
    font-size: 50px;
    font-weight: bold;
    text-align: left;
    align-items: left;
    margin-right:40%;
    width: 100vh;
 
}
.h1{
    color: var(--main);
    font-family: "DM Serif Display", cursive;
    font-size: 30px;
    text-align: left;
    align-items: left;
    padding: 10px 20px,   
 
}

.h2{
    color: var(--main);
    font-family: "Big Caslon";
    font-size:20px;
    text-align: left;
}

.ahref{
    text-decoration: none;
}

.linksContainer{
    font-family: "Big Caslon" !important;
    color:var(--nudes);
    justify-content: left;
    align-items: left;
    text-align: left;
    width: 90%;
    
    max-width: 100%;
    margin:auto;
}
.link{
    font-family: "Big Caslon";
    color: var(--main);
    font-size: 15px;
    text-decoration: underline;
}

.link2{
    font-family: "Big Caslon";
    color: var(--main);
    font-size: 15px;
}


.link:hover{
    color: var(--secondary-dark);
}
@media only screen and (max-width:480px) {
    .container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 100px 0;
    height: 95vh;
    background-color: var(--main);
    
    }


    .linksContainer{
        text-align: center;
        margin-top:auto;
        text-align: justify;
      
    }

    .link{
        font-size: 16px;
    }
}

.h5{
    color: var(--main);
    font-size: 15px;
    font-family: "Big Caslon";
    font-weight: lighter;
    font-style: oblique;
    }
.text-container {
    font-family: "Big Caslon";
    font-weight: lighter;
    color: var(--main);
    font-size: 15px;
    margin: auto; /* Center the container horizontally */
  }
  

  
  .first-part {
    float: left;
  }
  
  .second-part {
    float: left;
  }



@media only screen and (max-width:480px) {

    .partyDetailsContainer{
        background:none;
        }

    .partyDetailsSubcontainer{

        flex-direction: column;
   height: 90%;
    }

    .classContainer{

     
     
 
        height: 460px;
        width: 300px;
        margin-bottom: 20px;
        
    }

    .text{
        font-size: 14px;
    }

    .title{
        margin-bottom:20px
    }
}

  
  /* styles for tablet devices */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    body {
      font-size: 14px;
      width: 90%;
      margin: auto;
    }
  }
  
  /* styles for mobile devices */
  @media only screen and (max-width: 767px) {
    body {
      font-size: 12px;
      width: 100%;
      margin: 0;
    }
  }


   
.button{
  
    max-height: 70px;
    margin: 20px;
    width: 200px;
    background-color: white;
    color: var(--lines);
    border-radius: 20px;
    border: none;
    display: flex;
  justify-content: center;
  align-items: center;
padding: 8px 20px;
  cursor: pointer;
  border: 1px solid var(--lines);
  box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.47);
-webkit-box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.3);
-moz-box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.3);
}

.button:hover{
    background-color: var(--lines);
    color: white;

  transition: 2s;
}