 
.button{
  
    max-height: 70px;
    margin: 20px;
    width: 200px;
    background-color: white;
    color: var(--lines);
    border-radius: 20px;
    border: none;
    display: flex;
  justify-content: center;
  align-items: center;
padding: 8px 20px;
  cursor: pointer;
  border: 1px solid var(--lines);
  box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.47);
-webkit-box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.3);
-moz-box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.3);
}

.button:hover{
    background-color: var(--lines);
    color: white;

  transition: 2s;
}