
.wood{
    height: 200vh;
    width: 100%;
    background-color: var(--secondary);
    /* background-image: url('../assets/img/maderaRosas.jpg'); */
    /* background-size: 100%;
    background-position: bottom right; */
    position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   padding: 100px 0;
}
.wood::after{
    background-image: url(../assets/icons/img_ondas05.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100%;
    width: 100%;
    bottom: -1px;
}

/* .wood::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/flora-312815.svg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: -100vh;
    left: 0;
} */

.borderFlower{
    width: 350px;
    position: absolute;
    left: -70px;
    top: 0;
    transform: rotate(30deg);
}

.borderFlower2{
    width: 600px;
    position: absolute;
    left: -70px;
    top: 600px;
}

.borderFlower3{
    width: 400px;
    position: absolute;
    right: -70px;
    top: 0px;
    z-index: 10;
    transform: rotate(-60deg);
}

.borderFlower4{
    width: 1000px;
    position: absolute;
    right: -60px;
    top: 300px;
    z-index: 10;
    animation: flower 1s  ease-in-out;
}

@keyframes flower{
100%{right: -100px}
}

.smallPicture1{
    position: absolute;
    width: 90vw;
    border: 3px solid white;
z-index: 2;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin: 0 auto; 
}

.photoBigContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
   
top: 200px;
   

    width: 100vw;
z-index: 60;
}

.photoGroupContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
}

.photoContainer{
    width: 30vw;
    height: 30vw;
    border: 3px solid white;
    overflow: hidden;
    margin: 5px;
    position: relative;
    border-radius: 10%;
}


.imgInContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: initial;
    top: 500;
left: 500;
right: 500;
bottom: 0;
height: 100%;
}

.title{

    background-color: var(--lines);
    padding: 10px 20px;
    position: relative;
    font-family: "DM Serif Display", serif;
    color: white;
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
}


.title::before{
    background-image: url(../assets/icons/img_cinta01.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: right;
    height: 80%;
    width: 50px;
    left: -50px;
    top: 5px;
}

.title::after{
    background-image: url(../assets/icons/img_cinta02.svg);
    position: absolute;
    content: '';
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: left;
    height: 80%;
    width: 50px;
    right: -50px;
    top: 5px;
}

@media only screen and (max-width:480px) {

    
.wood{
height: 250vh;
   padding: 20px 0;

}

    .photoGroupContainer{
   
        flex-direction: column;
       }
      
       .photoContainer{
        width: 300px;
        height: 300px;
        border: 3px solid white;
        overflow: hidden;
        margin: 5px;
        position: relative;
        border-radius: 10%;
    }

    .borderFlower{
top:500px;
    }
    .borderFlower3{
        top:30px;
    }

    .photoBigContainer{


    justify-content: space-between;
    height: 70%;

    margin-top: 0px;
    }

    .wood::after{
   
        width: 101%;
  
    }
}
