
.lastPage{
    height: 100vh;
    width: 100%;
    background-image: url('');
    position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.lastPage::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: -100vh;
    left: 0;
}

.finalGreeting{
    background-color: var(--color-1);
    color: white;
    width: 100%;
    height: 30vh;
border-top-left-radius: 50%;
}